import { Box, Typography, Stack, useTheme, Grid, Link } from '@mui/material';

import { m } from 'framer-motion';
import RegisterIllustration from '@/assetes/illustrations/register-illustration';
import AddPlaylistIllustration from '@/assetes/illustrations/add-playlist-illustration';
import ActivateIllustration from '@/assetes/illustrations/activate-illustration';
import DoneIllustration from '@/assetes/illustrations/done-illustration';
import { RouterLink } from '@/app/components/router-link/router-link';
import { paths } from '@/app/utilities/consts';
import InViewAnimationComponent from '@/app/components/in-view-animation-container';

const items = [
  {
    title: 'Register for free',
    description: 'Just install for different platforms ',
    component: <RegisterIllustration />,
    link: {
      title: 'here',
      href: paths.download,
    },
  },
  {
    title: 'Add your playlists',
    description: 'Add your m3u and xc playlists ',
    component: <AddPlaylistIllustration />,
    link: {
      title: 'here',
      href: paths.managePlaylist,
    },
  },
  {
    title: 'Activate your device',
    description: 'Activate your device using your card or Paypal ',
    component: <ActivateIllustration />,
    link: {
      title: 'here',
      href: paths.activate,
    },
  },
  {
    title: 'You are done!',
    description: "That's it",
    component: <DoneIllustration />,
  },
];

export default function HowToUse() {
  const theme = useTheme();

  return (
    <Box
      sx={{
        mx: 'auto',
        width: {
          md: '100%',
          lg: '1200px',
        },
        pt: 15,
        pb: 15,
      }}
      id="how-to-use"
    >
      <Stack gap={6}>
        <Stack alignItems="center" gap={2} paddingX={3}>
          <Typography variant="h3">How to use our Smarters Max IPTV?</Typography>
          <Typography
            variant="h6"
            sx={{
              maxWidth: 600,
              textAlign: 'center',
            }}
            color={theme.palette.text.secondary}
          >
            Smarters Max IPTV has a simple workflow to use and activate, Just enjoy it!
          </Typography>
        </Stack>
        <Stack paddingX={3}>
          <Grid container spacing={3}>
            {items.map((data, index) => (
              <Grid key={index} item xs={12} sm={6} md={6}>
                <m.div whileHover={{ y: -10, transition: { type: 'spring', stiffness: 300 } }}>
                  <InViewAnimationComponent>
                    <Stack
                      sx={{
                        bgcolor: theme.palette.background.neutral,
                        borderRadius: '10px',
                        boxShadow: theme.customShadows.z24,
                        // cursor: 'pointer',
                      }}
                      p={3}
                      gap={2}
                      alignItems="center"
                      textAlign="center"
                    >
                      <Box sx={{ height: 200, m: 2 }}>{data.component}</Box>

                      <Typography variant="subtitle1">{data.title}</Typography>
                      <Typography variant="body2">
                        {data.description}{' '}
                        {data.link && (
                          <Link
                            component={RouterLink}
                            href={data.link.href ?? ''}
                            sx={{ color: theme.palette.text.primary }}
                          >
                            {data.link.title}
                          </Link>
                        )}
                      </Typography>
                    </Stack>
                  </InViewAnimationComponent>
                </m.div>
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Stack>
    </Box>
  );
}
