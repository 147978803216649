/* eslint-disable jsx-a11y/media-has-caption */

import Dialog from '@mui/material/Dialog';

import { Slide } from '@mui/material';

import { forwardRef } from 'react';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function VideoModal({ dialog }) {
  return (
    <>
      <Dialog
        keepMounted={false}
        open={dialog.value}
        onClose={dialog.onFalse}
        scroll="body"
        fullWidth
        maxWidth="md"
        TransitionComponent={Transition}
      >
        <div
          style={{
            position: 'relative',
            width: '100%',
            maxWidth: '100%',
            paddingBottom: '56.25%',
            height: '0',
          }}
        >
          <video
            style={{
              position: 'absolute',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%',
            }}
            controls
            autoPlay
          >
            <source src="/assets/video/ad.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </Dialog>
    </>
  );
}
