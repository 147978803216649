'use client';

import ScrollProgress from '@/app/components/scroll-progress';
import MasterLayout from '@/app/components/layout/master-layout';
import HeroBanner from './hero-banner';
import OurFeatures from './our-features';
import { BackToTop } from '@/app/components/back-to-top';
import DownloadApps from './download-apps';
import HowToUse from './how-to-use';

export default function HomeView() {
  return (
    <MasterLayout>
      <ScrollProgress />
      <HeroBanner />
      <OurFeatures />
      <DownloadApps />
      <HowToUse />
      <BackToTop />
    </MasterLayout>
  );
}
